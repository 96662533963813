@media screen and (max-width:767px) {
    div.candidate-modal-success {
        > div.modal-content{
            border-radius: 6px;
            height: 436px;
            width: 336px;
            margin: auto;
            > .modal-header {
                border-radius: 6px;
                height: 100%;
                padding: 20px;
                width: 100%;
                > img.close-btn {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 14px;
                    height: 14px;;
                    margin-left: auto;
                    cursor: pointer;
                }
                > .success-modal-body {
                    height: 100%;
                    border-radius: 6px;
                    background-color: #ffffff;
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
    
                    > h2 {
                        color: #222222;
                        font-family: HiraKakuProN-W6;
                        font-size: 1.8rem;
                        height: 18px;
                        line-height: 1;
                        margin-top: 20px;
                        margin-bottom: 15px;
                    }
                    .image {
                        width: 240px;
                        height: 210px;
                        object-fit: contain;
                        margin-bottom: 10px;
                    }
                    p {
                        height: 21px;
                        font-family: HiraKakuProN-W6;
                        font-size: 1.6rem;
                        line-height: 1.3;
                        text-align: center;
                        color: #222222;
                        margin-bottom: 16px;
                    }
                    button.download-btn {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        border: 1px solid transparent;
                        border-radius: 30px;
                        padding: 1px;
                        text-decoration:none;
                        width: 100%;
                        height: 100%;
                        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        height: 40px;
                        margin: 0 auto;
                        width: 200px;

                        > span {
                            align-items: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            color: #ff3b48;
                            display: flex;
                            font-size: 1.4rem;
                            height: 100%;
                            letter-spacing: 1px;
                            justify-content: center;
                            width: 100%;
                        }

                        > span:hover {
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    div.candidate-modal-failed {
        > div.modal-content{
            border-radius: 6px;
            height: auto;
            margin: auto;
            width: auto;
            > .modal-header {
                border-radius: 6px;
                height: 100%;
                padding: 20px;
                width: 100%;
                > img.close-btn {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 14px;
                    height: 14px;;
                    margin-left: auto;
                    cursor: pointer;
                }
                > .vote-limit-over-modal-body,
                > .end-period-modal-body,
                > .faild-modal-body,
                > .login-failed-modal-body {
                    width: 100%;
                    height: auto;
                    text-align: center;
                    padding: 10px;
                    > h3 {
                        font-family: HiraKakuProN-W6;
                        font-size: 1.4rem;
                        line-height: 1;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        text-align: center;
                    }

                    p {
                        color: #222222;
                        font-family: HiraKakuProN-W6;
                        font-size: 1.2rem;
                        line-height: 1.3;
                        margin-bottom: 0;
                        text-align: center;
                    }
                    .ok-btn {
                        border-radius: 19px;
                        background-color: #ffffff;
                        border: solid 2px #909090;
                        height: 36px;
                        margin-top: 25px;
                        width: 130px;

                        > span {
                            color: #222222;
                            font-size: 1.4rem;
                            margin: auto;
                        }
                    }
                    > div.btns {
                        display: flex;
                        justify-content: space-between;
                        > button.login-btn {
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            border-radius: 30px;
                            padding: 1px;
                            text-decoration:none;
                            height: 36px;
                            margin-top: 25px;
                            width: 130px;

                            > span {
                                align-items: center;
                                background-color: #ffffff;
                                border-radius: 30px;
                                color: #ff3b48;
                                display: flex;
                                font-size: 1.4rem;
                                height: 100%;
                                letter-spacing: 1px;
                                justify-content: center;
                                width: 100%;
                            }

                            > span:hover {
                                background: linear-gradient(to right,#ff7717,#ff0078);
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) {
    div.candidate-modal-success {
        > div.modal-content{
            border-radius: 6px;
            height: 472px;
            margin: auto;
            width: 422px;
            > .modal-header {
                border-radius: 6px;
                height: 100%;
                padding: 20px;
                width: 100%;
                > img.close-btn {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 14px;
                    height: 14px;;
                    margin-left: auto;
                    cursor: pointer;
                }
                > div.success-modal-body {
                    height: 100%;
                    border-radius: 6px;
                    background-color: #ffffff;
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
    
                    > h2 {
                        color: #222222;
                        font-family: HiraKakuProN-W6;
                        font-size: 1.8rem;
                        height: 18px;
                        line-height: 1;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                    > img.image {
                        width: 258px;
                        height: 226px;
                        object-fit: contain;
                        margin-bottom: 5px;
                    }
                    > div.app-store-image {
                        > div.app-bnr {
                            margin: auto 0;
                            position: relative;
                    
                            > div.desc {
                                text-align: center;
                                margin-bottom: 10px;
                                > div {
                                    letter-spacing: 2px;
                                    line-height: 1.5;
                                    font-size: 1.8rem;
                                    text-align: left;
                                    width: 240px;
                                }
                                margin-left: 10px;
                                color: #222222;
                                font-size: 1rem;
                            }
                            > div.download-bnr {
                                align-items: center;
                                border-radius: 22.5px;
                                background-image: linear-gradient(to left, #15b4c3, #78eb4a);
                                display: flex;
                                height: auto;
                                justify-items: center;
                                min-height: 45px;
                                right: 0;
                                max-width: 414px;
                                width: 100%;
                
                                > span {
                                    color: #ffffff;
                                    display: block;
                                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                                    font-size: 1.6rem;
                                    letter-spacing: 1.5px;
                                    position: relative;
                                    margin-left: 15px;
                                }
                                img.mixchyell-qr {
                                    width: 100px;
                                    height: 100px;
                                    position: absolute;
                                    right: 20px;
                                    bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    div.candidate-modal-failed {
        > div.modal-content{
            border-radius: 6px;
            height: 179px;
            margin: auto;
            width: 420px;
            > .modal-header {
                border-radius: 6px;
                height: 100%;
                padding: 20px;
                width: 100%;
                > img.close-btn {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 14px;
                    height: 14px;;
                    margin-left: auto;
                    cursor: pointer;
                }
                > .vote-limit-over-modal-body,
                > .end-period-modal-body,
                > .faild-modal-body,
                > .login-failed-modal-body {
                    width: 100%;
                    height: auto;
                    margin: auto;
                    text-align: center;
                    > h3 {
                        font-family: HiraKakuProN-W6;
                        font-size: 1.8rem;
                        line-height: 1;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        text-align: center;
                    }

                    p {
                        color: #222222;
                        font-family: HiraKakuProN-W6;
                        font-size: 1.6rem;
                        line-height: 1.3;
                        margin-bottom: 0;
                        text-align: center;
                    }

                    button.ok-btn {
                        border-radius: 19px;
                        background-color: #ffffff;
                        border: solid 2px #909090;
                        height: 35px;
                        margin-top: 25px;
                        width: 154px;

                        > span {
                            color: #222222;
                            font-size: 1.4rem;
                            margin: auto;
                        }
                    }

                    .type-double {
                        margin-right: 32px;
                    }
                    
                    button.login-btn {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        border-radius: 30px;
                        padding: 1px;
                        text-decoration:none;
                        height: 35px;
                        margin-top: 25px;
                        width: 154px;

                        > span {
                            align-items: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            color: #ff3b48;
                            display: flex;
                            font-size: 1.4rem;
                            height: 100%;
                            letter-spacing: 1px;
                            justify-content: center;
                            width: 100%;
                        }

                        > span:hover {
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    div#contests {
        > div.contests {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto 5px;
        }

        > div.title {
            height: 16px;
            margin: 30px auto 20px;
            text-align: center;

            > h1 {
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                color: #333333;
            }
        }
    }
}

@media screen and (max-width:1023px) and (min-width:768px) {
    div#contests {
        > div.contests {
            display: flex;
            flex-wrap: wrap;
            margin: 0 10px 35px;
            &:after{
                content:"";
                display: block;
                height:0;
                width: 320px;
            }
        }

        > div.title {
            height: 16px;
            margin: 40px auto 25px;
            text-align: center;

            > h1 {
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                color: #333333;
            }
        }
    }
}

@media screen and (min-width:1024px) {
    div#contests {
        > div.contests {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 20px 35px;
            &:after{
                content:"";
                display: block;
                height:0;
                width: 320px;
            }
        }

        > div.title {
            height: 16px;
            text-align: center;
            margin: 40px auto 25px;

            > h1 {
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                color: #333333;
            }
        }
    }
}
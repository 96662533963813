#copyright {
    @media screen and (max-width:767px){
        text-align: center;
    }
    color: #000000;
    font-family: HiraginoSans-W3;
    font-size: 11px;
    letter-spacing: 0.79px;
    text-align: right;
    p {
        margin-top: 10px;
        margin-bottom: 0px;
    }
}
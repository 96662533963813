#bnr {
    @media screen and (max-width:767px){
        margin-top: 25px;
    }
    @media screen and (min-width:768px){
        margin-top: 40px;
    }
    font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    text-align: center;
    width: 100%;
    height: auto;
    margin: auto;
    > div.col {
        padding: 0 20px;
    }

    div.app-bnr {
        margin: auto 0;
        position: relative;
        

        > div.desc {
            @media screen and (max-width:767px) {
                font-size: 2.2rem;
                text-align: left;
                margin-bottom: 20px;
                line-height: 1.4;
                letter-spacing: 1px;
                > div {
                    font-size: 1.4rem;
                    line-height: 1.3;
                    letter-spacing: 1px;
                }
            }
            @media screen and (min-width:768px) {
                text-align: center;
                margin-bottom: 10px;
                > div {
                    letter-spacing: 2px;
                    line-height: 1.5;
                    font-size: 1.8rem;
                    text-align: left;
                    margin: 0 auto;
                    width: 241px;
                }
            }
            color: #222222;
            font-size: 1rem;

            > p {
                color: #ff3b48;
                font-size: 2.2rem;
            }
        }

        > div.app-store-image {
            @media screen and (max-width:767px) {
                margin-top: 30px;
            }
            @media screen and (min-width:768px) {
                margin-bottom: 20px;
                margin-left: 70px;
            }

            button.download-btn {
                background: linear-gradient(to right,#ff7717,#ff0078);
                border: 1px solid transparent;
                border-radius: 30px;
                margin-bottom: 25px;
                padding: 1px;
                text-decoration:none;
                @media screen and (max-width:767px) {
                    width: 219px;
                }

                > span {
                    background-color: #ffffff;
                    border-radius: 30px;
                    color: #ff3b48;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width:767px) {
                        font-size: 1.4rem;
                    }
                    @media screen and (min-width:768px) {
                        font-size: 1.6rem;
                    }
                    height: 40px;
                    letter-spacing: 1px;
                    text-align: center;
                }
                span:hover {
                    background: linear-gradient(to right,#ff7717,#ff0078);
                    color: #ffffff;
                }
            }

            div.download-bnr {
                align-items: center;
                border-radius: 22.5px;
                background-image: linear-gradient(to left, #15b4c3, #78eb4a);
                display: flex;
                height: auto;
                justify-items: center;
                min-height: 45px;
                right: 0;
                max-width: 414px;
                width: 100%;

                > span {
                    color: #ffffff;
                    display: block;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 1.5px;
                    position: relative;
                    @media screen and (max-width:768px) and (min-width:576px) {
                        margin-left: 15px;
                        font-size: 1.3rem;
                    }
                    @media screen and (max-width:1024px) and (min-width:769px) {
                        margin-left: 30px;
                    }
                    @media screen and (min-width: 1025px) {
                        margin-left: 35px;
                    }
                }
                img.mixcma {
                    position: absolute;
                    @media screen and (max-width:768px) and (min-width:576px) {
                        width: 80px;
                        height: 70px;
                        left: 5%;
                        bottom: 5%;
                    }
                    @media screen and (max-width:1024px) and (min-width:769px) {
                        width: 100px;
                        height: 90px;
                        margin-left: 30px;
                        left: -3%;
                        bottom: 5%;
                    }
                    @media screen and (min-width: 1025px) {
                        left: -8%;
                        bottom: 5%;
                        margin-left: 35px;
                    }
                }
                img.mixchyell-qr {
                    right: 6%;
                    bottom: 15%;
                    position: absolute;
                    @media screen and (max-width:768px) and (min-width:576px) {
                        width: 15%;
                        right: 0;
                        height: auto;
                    }
                    @media screen and (max-width:1024px) and (min-width:769px) {
                        right: 2%;
                    }
                    @media screen and (min-width: 1025px) {
                        right: 6%;
                        bottom: 15%;
                    }
                }
            }
        }
    }

    > div.image-store {
        max-width: 420px;
        margin: 0;

        > img {
            width: 100%;

        }
    }
}

div.border-line {
    border-top: 1px solid #ededed;
    margin: 0 calc(50% - 50vw);
}
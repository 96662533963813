@media screen and (max-width:767px) {
    #candidates {
        overflow: hidden;
        > div.header-image {
            margin-bottom: 16px;
            > img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        > div.candidates-title {
            color: #333333;
            font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1.6rem;
            letter-spacing: 1.23px;
            text-align: center;
            margin-top: 30px;
        }
        > div.candidates {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            &:after{
                content:"";
                display: block;
                height:0;
                width: 150px;
            }
        }
        > div.contest-info {
            background-color: #f0f0f0;
            margin: 0 calc(50% - 50vw);
            margin-top: 66px;

            > div.title {
                font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                color: #333333;
                text-align: center;
                padding-top: 20px;
                margin-bottom: 30px;
            }

            > div.contest-info-items {
                align-items: stretch;
                color: #333333;
                display: flex;
                flex-wrap: wrap;
                font-size: 1.4rem;
                letter-spacing: 0.88px;
                justify-content: center;
                margin: auto 10px;
                width: auto;
                > div.item {
                    align-items: center;
                    border-bottom: solid 1px #d5d5d5;
                    display: flex;
                    margin-top: 20px;
                    padding-bottom: 20px;
                    width: 100%;
                    > div.name {
                        font-size: 1.2rem;
                        margin-left: 10px;
                        width: 130px;
                        > span {
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        }
                    }
                    > div.val {
                        font-size: 1.3rem;
                        margin-left: 30px;
                        width: 280px;
                        > span {
                            letter-spacing: 1.08px;
                        }
                    }
                }
            }
        }
        > div#to-owner-group-info {
            margin-top: 30px;
        }
    }
}

@media screen and (max-width: 1024px) and (min-width:768px) {
    #candidates {
        > div.header-image {
            margin: 20px 20px;
            > img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        > div.candidates-title {
            color: #333333;
            font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1.6rem;
            letter-spacing: 1.23px;
            text-align: center;
            margin-top: 40px;
        }
        > div.candidates {
            display: flex;
            flex-wrap: wrap;
            margin: 0 10px;
            &:after{
                content:"";
                display: block;
                margin: auto;
                width:33%;
            }
        }
        > div.contest-info {
            background-color: #f0f0f0;
            margin: 0 calc(50% - 50vw);
            margin-top: 60px;
            padding-bottom: 80px;

            > div.title {
                font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                color: #333333;
                text-align: center;
                padding-top: 40px;
                margin-bottom: 20px;
            }

            > div.contest-info-items {
                align-items: stretch;
                color: #333333;
                display: flex;
                flex-wrap: wrap;
                font-size: 1.4rem;
                letter-spacing: 0.88px;
                justify-content: center;
                margin: auto;
                width: auto;
                > div.item {
                    align-items: center;
                    border-bottom: solid 1px #d5d5d5;
                    display: flex;
                    margin-top: 20px;
                    padding-bottom: 20px;
                    width: 45%;
                    > div.name {
                        margin-left: 20px;
                        width: 130px;
                        > span {
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        }
                    }
                    > div.val {
                        margin-left: 30px;
                        width: 280px;
                        > span {
                            letter-spacing: 1.08px;
                        }
                    }
                }
                > div:nth-child(odd) {
                    margin-right: 50px;
                }
            }
        }
    }
}

@media screen and (min-width:1025px) {
    #candidates {
        > div.header-image {
            margin: 20px 0;
            > img {
                width: 1040px;
                height: 355px;
                object-fit: contain;
            }
        }
        > div.candidates-title {
            color: #333333;
            font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1.6rem;
            letter-spacing: 1.23px;
            text-align: center;
            margin-top: 40px;
        }
        > div.candidates {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 20px;
            &:before{
                content:"";
                display:block;
                height:0;
                order:1;
                width: 235px;
            }
            &:after{
                content:"";
                display: block;
                height:0;
                width: 235px;
            }
        }
        > div.contest-info {
            background-color: #f0f0f0;
            margin: 0 calc(50% - 50vw);
            margin-top: 60px;
            padding-bottom: 80px;

            > div.title {
                font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                color: #333333;
                text-align: center;
                padding-top: 40px;
                margin-bottom: 20px;
            }

            > div.contest-info-items {
                align-items: stretch;
                color: #333333;
                display: flex;
                flex-wrap: wrap;
                font-size: 1.4rem;
                letter-spacing: 0.88px;
                justify-content: center;
                margin: auto;
                width: 1040px;
                > div.item {
                    align-items: center;
                    border-bottom: solid 1px #d5d5d5;
                    display: flex;
                    margin-top: 20px;
                    padding-bottom: 20px;
                    width: 480px;
                    > div.name {
                        margin-left: 20px;
                        width: 130px;
                        > span {
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        }
                    }
                    > div.val {
                        margin-left: 30px;
                        width: 280px;
                        > span {
                            letter-spacing: 1.08px;
                        }
                    }
                }
                > div:nth-child(odd) {
                    margin-right: 50px;
                }
            }
        }
    }
}
#contest-vote-limit {
    font-family: HiraginoSans-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    font-size: 1.4rem;
    letter-spacing: 1.08px;

    > div.left {
        color: #666666;
    }
    > span.right {
        color: #ff9a08;
        font-family: HiraginoSans-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;;
        > span.num {
            font-size: 2rem;
            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            letter-spacing: 1.54px;
        }
    }
}
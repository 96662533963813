@media screen and (max-width:767px) {
    div#contest-column {
        border-radius: 4px;
        border: solid 1px #ededed;
        font-family: HiraginoSans-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: auto;
        transition: opacity .3s;
        max-width: 335px;
        margin: 0 auto 25px;
        min-height: 211px;
        width: auto;

        > a {
            color: unset;
            display: block;
            height: 100%;
            text-decoration: none;
            width: 100%;

            > div.contest-image {
                margin: 5px 5px 10px 5px;
                width: auto;
                > img {
                    object-fit: contain;
                    max-width: 325px;
                    max-height: 115px;
                    height: 100%;
                    width: 100%;
                }
            }

            > div.top {
                margin: 0 5px;

                > div.contest-status {
                    display: inline-block;
                    height: 19px;
                }

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6;
                    font-size: 1.2rem;
                    letter-spacing: 1px;
                    margin-left: 10px;
                }
            }

            > div.contest-name {
                color: #333333;
                display: -webkit-box;
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                line-height: 1.4;
                letter-spacing: 0.89px;
                margin: 5px 5px 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            > div.contest-period {
                color: #666666;
                font-family: NotoSansCJKjp, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.2rem;
                letter-spacing: 1px;
                line-height: 1;
                margin: 5px 5px 15px;
            }
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

@media screen and (max-width:1023px) and (min-width:768px) {
    div#contest-column {
        border-radius: 4px;
        border: solid 1px #ededed;
        font-family: HiraginoSans-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: auto;
        transition: opacity .3s;
        width: 228px;
        margin: 0 10px 25px;

        > a {
            color: unset;
            display: block;
            height: 100%;
            text-decoration: none;
            width: 100%;

            > div.contest-image {
                width: auto;
                margin: 5px 5px 8px 5px;
                > img {
                    object-fit: contain;
                    height: auto;
                    width: 100%;
                }
            }

            > div.top {
                margin: 0 5px;

                > div.contest-status {
                    display: inline-block;
                    height: 19px;
                }

                > div.college-name {
                    margin-left: 5px;
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                }
            }

            > div.contest-name {
                color: #686666;
                display: -webkit-box;
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.8rem;
                line-height: 1.4;
                letter-spacing: 1px;
                margin: 5px 5px 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            > div.contest-period {
                color: #666666;
                font-family: NotoSansCJKjp, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.3rem;
                letter-spacing: 1px;
                margin: 5px 5px 10px;
            }
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

@media screen and (min-width:1024px) {
    div#contest-column {
        border-radius: 4px;
        border: solid 1px #ededed;
        font-family: HiraginoSans-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: auto;
        transition: opacity .3s;
        width: 320px;
        margin: 0 0 25px;

        > a {
            color: unset;
            display: block;
            height: 100%;
            text-decoration: none;
            width: 100%;

            > div.contest-image {
                width: 310px;
                margin: 5px auto 8px;
                > img {
                    object-fit: contain;
                    width: 100%;
                }
            }

            > div.top {
                margin: 0 5px;

                > div.contest-status {
                    display: inline-block;
                    height: 19px;
                }

                > div.college-name {
                    margin-left: 5px;
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                }
            }

            > div.contest-name {
                color: #333333;
                display: -webkit-box;
                font-family: HiraginoSans-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.8rem;
                line-height: 1.4;
                letter-spacing: 1px;
                margin: 5px 5px 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            > div.contest-period {
                color: #666666;
                font-family: NotoSansCJKjp, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.3rem;
                letter-spacing: 1px;
                margin: 5px 5px 10px;
            }
        }
        &:hover {
            opacity: 0.8;
        }
    }
}
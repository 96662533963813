@media screen and (max-width:767px) {
    div#header {
        border-bottom: solid #dfdfdf 1px;
        display: flex;
        height:35px;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;

        > div.site-image {
            margin: auto;
            height: 24px;
            width: 150px;
            img.site-logo {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        > div.right {
            bottom: 0;
            right: 20px;
            position: absolute;
            width: 35px;
            height: 35px;
            > div.logout {
                width: 35px;
                height: 35px;
                > a{
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) {
    div#header {
        display: flex;
        @media screen and (max-width:1024px) and (min-width:768px) {
            margin: 10px 20px;
        }
        @media screen and (min-width:1025px) {
            margin: 10px auto;
        }

        > .site-image {
            margin: auto 0;
            max-width: 768px;
            height: auto;
            width: 100%;
            img.site-logo {
                object-fit: contain;
            }
        }
        > div.right {
            display: flex;
            align-items: center;
            height: 40px;
            margin-left: auto;

            > div.logout {
                margin-left: 30px;
    
                > a{
                    > button {
                        width: 88px;
                        height: 22px;
                        background-color: #fafafa;
                        border-radius: 11px;
                        border: solid 1px #dfdfdf;
                        padding: 0;
    
                        > span {
                            color: #222222;
                            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.2rem;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
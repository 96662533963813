@media screen and (max-width:767px) {
    div#sub-footer {
        align-items: center;
        color: #222222;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.1rem;
        margin: 30px 20px 40px;

        > div.left {
            text-align: center;
            width: 100%;
            > div.info {
                display: inline-block;
            }

            > div.company-info {
                display: inline-block;
            }

            > div.privacy-policy {
                display: inline-block;
            }
            a {
                color: #222222;
            }

            > span.separator {
                margin: auto 10px;
            }
        }
        > div.right {
            margin-top: 40px;
        }
    }
}
@media screen and (max-width: 1024px) and (min-width:768px) {
    div#sub-footer {
        align-items: center;
        display: flex;
        color: #222222;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.2rem;
        margin: 40px 20px 40px;

        > div.left {
            > div.info {
                display: inline-block;
            }

            > div.company-info {
                display: inline-block;
            }

            > div.privacy-policy {
                display: inline-block;
            }
            a {
                color: #222222;
            }

            > span.separator {
                margin: auto 20px;
            }
        }
        > div.right {
            margin-left: auto;
        }
    }
}

@media screen and (min-width:1025px) {
    div#sub-footer {
        align-items: center;
        display: flex;
        color: #222222;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.2rem;
        margin-top: 40px;
        margin-bottom: 40px;

        > div.left {
            > div.info {
                display: inline-block;
            }

            > div.company-info {
                display: inline-block;
            }

            > div.privacy-policy {
                display: inline-block;
            }
            a {
                color: #222222;
                text-decoration: none;
            }

            > span.separator {
                margin: auto 20px;
            }
        }
        > div.right {
            margin-left: auto;
        }
    }
}
@media screen and (max-width:767px) {
    div#breadcrumbs {
        align-items: center;
        background-color: #e4e4e4;
        display: flex;
        height: 35px;
        margin: 0 calc(50% - 50vw);
        color: #9a9a9a;

        > div.breadcrumbs-item {
            color: #9a9a9a;
            font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;;
            font-size: 1rem;
            margin: auto 20px;
            width: 100%;
            a {
                color: #9a9a9a;
                font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;;
            }
            span {
                font-size: 1rem;
            }
        }

        a.active {
            // text-decoration: none;
            color: #9a9a9a;
        }
    }
}
@media screen and (min-width:768px) {
    div#breadcrumbs {
        align-items: center;
        background-color: #f0f0f0;
        display: flex;
        height: 35px;
        margin: 0 calc(50% - 50vw);
        color: #9a9a9a;

        > div.breadcrumbs-item {
            color: #9a9a9a;
            font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;;
            font-size: 1rem;
            @media screen and (max-width:1024px) and (min-width:768px) {
                margin: 0 20px;
            }
            @media screen and (min-width:1025px) {
                margin: 0 auto;
            }
            width: 1040px;
            a {
                color: #9a9a9a;
                font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;;
            }
            span {
                font-size: 1rem;
            }
        }

        a.active {
            // text-decoration: none;
            color: #9a9a9a;
        }
    }
}
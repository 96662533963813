div#owner-group-info {
    > div.owner-group-info {
        max-width: 620px;
        @media screen and (max-width:767px){
            margin: 20px 20px 0;
        }
        @media screen and (min-width:768px){
            margin: 40px auto 0;
        }

        > h1 {
            color: #333333;
            font-family: HiraKakuProN-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1.6rem;
            @media screen and (max-width:767px){
                margin: 20px auto 0;
                letter-spacing: 1px;
            }
            @media screen and (min-width:768px){
                margin: 40px auto 0;
                letter-spacing: 1.23px;
            }
            margin-bottom: 0;
            text-align: center;
        }

        > h2 {
            color: #222222;
            font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            @media screen and (max-width:767px){
                font-size: 1.3rem;
            }
            @media screen and (min-width:768px){
                font-size: 1.4rem;
            }
            line-height: 1.64;
            margin-top: 30px;
            margin-bottom:10px;

            > .list-mark {
                background-color: #ff0077;
                height: 21px;
                width: 2px;
            }

            + p {
                color: #222222;
                @media screen and (max-width:767px) {
                    font-size: 1.3rem;
                    line-height: 1.5;
                    margin-bottom: 15px;
                }
                @media screen and (min-width:768px) {
                    font-size: 1.4rem;
                    line-height: 1.64;
                    margin-bottom: 20px;
                }
            }
        }

        > div.to-owner {
            @media screen and (min-width:768px) {
                width: 620px;
            }
            @media screen and (max-width:767px) {
                max-width: 620px;
            }
            img {
                object-fit: contain;
                margin: auto;
                margin-bottom: 20px;
                width: 100%;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        > p.annotation {
            color: #909090;
            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            margin-top: 10px;
        }

        > div.introduction-record {
            margin: 0 auto;
            width: 100%;

            > img {
                height: auto;
                width: 100%;
                @media screen and (max-width:767px){
                    margin-bottom: 15px;
                }
                @media screen and (min-width:768px){
                    margin-bottom: 20px;
                }
            }
            > img:last-child {
                margin-bottom: 0;
            }
        }
    
        div.contactus {
            @media screen and (max-width:767px){
                margin-top: 30px;
            }
            @media screen and (min-width:768px){
                margin-top: 60px;
                margin-bottom: 50px;
            }
    
            > h3 {
                color: #222222;
                font-size: 1.4rem;
                line-height: 1.64;
                text-align: center;
                margin-bottom: 0px;
                @media screen and (max-width:767px){
                    font-size: 1.3rem;
                }
                @media screen and (min-width:768px){
                    font-size: 1.4rem;
                }
            }
    
            > div.contactus-btn {
                text-align: center;
                margin-top: 10px;
                @media screen and (max-width:767px){
                    margin-bottom: 30px;
                }
    
                button {
                    background: linear-gradient(to right,#ff7717,#ff0078);
                    border: 1px solid transparent;
                    border-radius: 30px;
                    margin: 0 auto;
                    padding: 1px;
                    text-decoration:none;
                    @media screen and (max-width:767px){
                        width: 215px;
                    }
                    @media screen and (min-width:768px){
                        width: 280px;
                    }
    
                    > span {
                        background-color: #ffffff;
                        border-radius: 30px;
                        color: #ff3b48;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 44px;
                        letter-spacing: 1px;
                        text-align: center;
                        @media screen and (max-width:767px){
                            font-size: 1.4rem;
                        }
                        @media screen and (min-width:768px){
                            font-size: 1.6rem;
                        }
        
                        > img {
                            background-color: #ffffff;
                            margin-right: 8px;
                            width: 20px;
                        }
                    }
    
                    > span:hover {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
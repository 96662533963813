@media screen and (max-width:767px) {
    div#candidate-profile {
        > div.candidate-interview {
            background-color: #f6f6f6;
            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            margin: 0 calc(50% - 50vw);
            padding-bottom: 30px;

            > div.interview-title {
                text-align: center;
                margin: 0 auto 20px;
                padding-top: 30px;
                color: #333333;

                > label {
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                }

                > h3 {
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 1.23px;
                    margin: 0;
                    word-wrap: break-word;
                }
            }

            > div.interview-items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                width: 100%;

                > div.item {
                    background-color: #ffffff;
                    border-radius: 1px;
                    margin: 0 20px;
                    margin-bottom: 10px;
                    padding: 15px 10px 0 10px;
                    height: auto;
                    width: 100%;

                    > p.question {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: #ffb4b4;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.6rem;
                        word-wrap: break-word;

                        > span.text {
                            color: #ffa1a1;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            letter-spacing: 1px;
                            word-wrap: break-word;
                        }
                    }
                    > p.answer {
                        color: #333333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-size: 1.4rem;
                        letter-spacing: 1.08px;
                        line-height: 1.7;
                        margin: 5px auto 15px;
                    }
                }
            }
        }
        > div#to-owner-group-info {
            margin-top: 30px;
        }
    }
}

@media screen and (max-width:1023px) and (min-width:768px) {
    div#candidate-profile {
        > div.candidate-interview {
            background-color: #f6f6f6;
            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            margin: 0 calc(50% - 50vw);
            padding-bottom: 50px;

            > div.interview-title {
                text-align: center;
                margin: 0 auto 30px;
                padding-top: 40px;
                color: #333333;

                > label {
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                }

                > h3 {
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 1.23px;
                    margin: 0;
                    word-wrap: break-word;
                }
            }

            > div.interview-items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                width: 720px;

                > div.item {
                    background-color: #ffffff;
                    border-radius: 1px;
                    margin-bottom: 10px;
                    padding: 15px 10px 0 10px;
                    min-height: 100px;
                    height: auto;
                    width: 350px;

                    > p.question {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: #ffb4b4;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.6rem;
                        word-wrap: break-word;

                        > span.text {
                            color: #ffa1a1;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            letter-spacing: 1px;
                            word-wrap: break-word;
                        }
                    }
                    > p.answer {
                        color: #333333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-size: 1.4rem;
                        letter-spacing: 1.08px;
                        line-height: 1.7;
                        margin: 5px auto 15px;
                    }
                }
                > div.item:nth-child(odd) {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media screen and (min-width:1024px) {
    div#candidate-profile {
        > div.candidate-interview {
            background-color: #f6f6f6;
            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            margin: 0 calc(50% - 50vw);
            @media screen and (max-width:767px){
                padding-bottom: 50px;
            }
            @media screen and (min-width:768px){
                padding-bottom: 50px;
            }

            > div.interview-title {
                text-align: center;
                @media screen and (min-width:768px){
                    margin: 0 auto 30px;
                    padding-top: 40px;
                }
                @media screen and (max-width:767px){
                    margin: 40px auto 30px;
                }
                color: #333333;

                > label {
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                }

                > h3 {
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 1.23px;
                    margin: 0;
                    word-wrap: break-word;
                }
            }

            > div.interview-items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                width: 860px;

                > div.item {
                    background-color: #ffffff;
                    border-radius: 1px;
                    margin-bottom: 10px;
                    padding: 15px 10px 0 10px;
                    min-height: 100px;
                    height: auto;
                    width: 420px;

                    > p.question {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: #ffb4b4;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.6rem;
                        word-wrap: break-word;

                        > span.text {
                            color: #ffa1a1;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            letter-spacing: 1px;
                            word-wrap: break-word;
                        }
                    }
                    > p.answer {
                        color: #333333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-size: 1.4rem;
                        letter-spacing: 1.08px;
                        line-height: 1.7;
                        margin: 5px auto 15px;
                    }
                }
                > div.item:nth-child(odd) {
                    margin-right: 20px;
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    div#contest-status {
        background-color: #ffffff;
        font-family: HiraKakuProN-W3, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.1rem;
        text-align: center;
        width: 70px;

        > div {
            height: 19px;
            border-radius: 2px;
        }
        > .before-vote {
            border: solid 1px #000340;
            color: #000340;
        }
        
        > .voting {
            border: solid 1px #ffa470;
            color: #ff9a08;
        }
        
        > .aggregate-vote {
            border: solid 1px #cacaca;
            color: #cacaca;
        }
        
        > .end-vote {
            border: solid 1px #56b73c;
            color: #56b73c;
        }
    }
}

@media screen and (max-width: 1024px) and (min-width:768px) {
    div#contest-status {
        background-color: #ffffff;
        font-family: HiraKakuProN-W3, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.1rem;
        text-align: center;
        width: 70px;

        > div {
            height: 19px;
            border-radius: 2px;
        }
        > .before-vote {
            border: solid 1px #000340;
            color: #000340;
        }
        
        > .voting {
            border: solid 1px #ffa470;
            color: #ff9a08;
        }
        
        > .aggregate-vote {
            border: solid 1px #cacaca;
            color: #cacaca;
        }
        
        > .end-vote {
            border: solid 1px #56b73c;
            color: #56b73c;
        }
    }
}

@media screen and (min-width:1025px) {
    div#contest-status {
        background-color: #ffffff;
        font-family: HiraKakuProN-W3, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.1rem;
        text-align: center;
        width: 70px;

        > div {
            height: 19px;
            border-radius: 2px;
        }
        > .before-vote {
            border: solid 1px #000340;
            color: #000340;
        }
        
        > .voting {
            border: solid 1px #ffa470;
            color: #ff9a08;
        }
        
        > .aggregate-vote {
            border: solid 1px #cacaca;
            color: #cacaca;
        }
        
        > .end-vote {
            border: solid 1px #56b73c;
            color: #56b73c;
        }
    }
}
@media screen and (max-width:767px) {
    div.vote-btn {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: 36px;
        margin: 0 auto;
        width: 130px;
        > button {
            border: 1px solid transparent;
        }

        > button.abled {
            background: linear-gradient(to right,#ff7717,#ff0078);
            border-radius: 30px;
            padding: 1px;
            text-decoration:none;
            width: 100%;
            height: 100%;

            > span {
                align-items: center;
                background-color: #ffffff;
                border-radius: 30px;
                color: #ff3b48;
                display: flex;
                font-size: 1.4rem;
                height: 100%;
                letter-spacing: 1px;
                justify-content: center;
                width: 100%;
            }

            > span:hover {
                background: linear-gradient(to right,#ff7717,#ff0078);
                color: #ffffff;
            }
        }

        > button.disabled {
            border-radius: 20px;
            border: solid 2px #e4e4e4;
            background-color: #ffffff;
            height: 100%;
            width: 100%;

            > span {
                color: #909090;
                font-size: 1.4rem;
                height: 100%;
                letter-spacing: 1.08px;
                text-align: center;
                width: 100%;
            }
        }

        > button.hide {
            display: none;
        }
    }
}
@media screen and (max-width: 1024px) and (min-width:768px) {
    div.vote-btn {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: 40px;
        margin: 0 auto;
        width: 180px;
        > button {
            border: 1px solid transparent;
        }

        > button.abled {
            background: linear-gradient(to right,#ff7717,#ff0078);
            border-radius: 30px;
            padding: 1px;
            text-decoration:none;
            width: 100%;
            height: 100%;

            > span {
                align-items: center;
                background-color: #ffffff;
                border-radius: 30px;
                color: #ff3b48;
                display: flex;
                font-size: 1.6rem;
                height: 100%;
                letter-spacing: 1px;
                justify-content: center;
                width: 100%;
            }

            > span:hover {
                background: linear-gradient(to right,#ff7717,#ff0078);
                color: #ffffff;
            }
        }

        > button.disabled {
            border-radius: 20px;
            border: solid 2px #e4e4e4;
            background-color: #ffffff;
            cursor: default;
            height: 100%;
            width: 100%;

            > span {
                color: #909090;
                font-size: 1.4rem;
                height: 100%;
                letter-spacing: 1.08px;
                text-align: center;
                width: 100%;
            }
        }

        > button.hide {
            display: none;
        }
    }
}
@media screen and (min-width:1025px) {
    div.vote-btn {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        height: 40px;
        margin: 0 auto;
        width: 180px;
        > button {
            border: 1px solid transparent;
        }

        > button.abled {
            background: linear-gradient(to right,#ff7717,#ff0078);
            border-radius: 30px;
            padding: 1px;
            text-decoration:none;
            width: 100%;
            height: 100%;
            position: relative;

            > span {
                align-items: center;
                background-color: #ffffff;
                border-radius: 30px;
                color: #ff3b48;
                display: flex;
                font-size: 1.6rem;
                height: 100%;
                letter-spacing: 1px;
                justify-content: center;
                width: 100%;
            }

            > span::after {
                color: #ffffff;
                position: absolute;
                align-items: center;
                border-radius: 30px;
                display: flex;
                font-size: 1.6rem;
                height: 100%;
                letter-spacing: 1px;
                justify-content: center;
                background: linear-gradient(to right,#ff7717,#ff0078);
                width: 100%;
                content: "応援する";
                opacity: 0;
                transition: .3s;
            }
            span:hover::after {
                opacity: 1;
            }
        }

        > button.disabled {
            border-radius: 30px;
            border: solid 2px #e4e4e4;
            background-color: #ffffff;
            cursor: default;
            height: 100%;
            width: 100%;

            > span {
                color: #909090;
                font-size: 1.6rem;
                height: 100%;
                letter-spacing: 1.08px;
                text-align: center;
                width: 100%;
            }
        }

        > button.hide {
            display: none;
        }
    }
}
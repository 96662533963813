@media screen and (max-width:768px) {
    div#candidate-column {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.4rem;
        width: 150px;

        > div.candidate-ranking {
            text-align: center;
            height: 30px;
        }
        > div.candidate-image {
            border-radius: 50%;
            height: 150px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            width: 150px;
            > a {
                > img {
                    width: 150px;
                    height: 150px;
                    object-fit: contain;
                    transition: 0.5s;
                }
                > img.hover {
                    transform: scale(1.1, 1.1);
                }

                > div {
                    align-items: center;
                    background: rgba(110, 110, 110, 0.2);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    left: 50%;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;

                    > span {
                        color: #ffffff;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 2rem;
                        letter-spacing: 6px;
                        margin: auto;
                    }
                }
            }
        }
        // TODO: hover
        // > div.candidate-image:hover {
            // box-shadow: 10px 10px 10px rgba(110, 110, 110, 0.2);
            // box-shadow: 10px 10px 10px linear-gradient(318deg, #ff0066 91%, #ffef6f 5%);
        // }

        > div.candidate-name {
            margin: 10px auto 15px;
            > p {
                color: #333333;
                font-family: HiraKakuProN-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                margin: 0;
                text-align: center;
                word-wrap : break-word;
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width:768px) {
    div#candidate-column {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.4rem;
        width: 33%;
        margin: auto;

        > div.candidate-ranking {
            text-align: center;
            height: 40px;
        }
        > div.candidate-image {
            border-radius: 50%;
            height: 235px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            width: 235px;
            > a {
                > img {
                    width: 235px;
                    height: 235px;
                    transition: 0.5s;
                    object-fit: contain;
                }
                > img.hover {
                    transform: scale(1.1, 1.1);
                }

                > div {
                    align-items: center;
                    background: rgba(110, 110, 110, 0.2);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    left: 50%;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;

                    > span {
                        color: #ffffff;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 2rem;
                        letter-spacing: 6px;
                        margin: auto;
                    }
                }
            }
        }
        // TODO: hover
        // > div.candidate-image:hover {
            // box-shadow: 10px 10px 10px rgba(110, 110, 110, 0.2);
            // box-shadow: 10px 10px 10px linear-gradient(318deg, #ff0066 91%, #ffef6f 5%);
        // }

        > div.candidate-name {
            margin: 15px auto;
            > p {
                color: #333333;
                font-family: HiraKakuProN-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                margin: 0;
                text-align: center;
                word-wrap : break-word;
            }
        }
    }
}

@media screen and (min-width:1025px) {
    div#candidate-column {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.4rem;
        width: 235px;

        > div.candidate-ranking {
            text-align: center;
            height: 40px;
        }
        > div.candidate-image {
            border-radius: 50%;
            height: 235px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            width: 235px;
            > a {
                > img {
                    width: 235px;
                    height: 235px;
                    transition: 0.5s;
                    object-fit: contain;
                }
                > img.hover {
                    transform: scale(1.1, 1.1);
                }

                > div {
                    align-items: center;
                    background: rgba(110, 110, 110, 0.2);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    left: 50%;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;

                    > span {
                        color: #ffffff;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 2rem;
                        letter-spacing: 6px;
                        margin: auto;
                    }
                }
            }
        }
        // TODO: hover
        // > div.candidate-image:hover {
            // box-shadow: 10px 10px 10px rgba(110, 110, 110, 0.2);
            // box-shadow: 10px 10px 10px linear-gradient(318deg, #ff0066 91%, #ffef6f 5%);
        // }

        > div.candidate-name {
            margin: 15px auto;
            > p {
                color: #333333;
                font-family: HiraKakuProN-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                font-size: 1.6rem;
                letter-spacing: 1.23px;
                margin: 0;
                text-align: center;
                word-wrap : break-word;
            }
        }
    }
}
@media screen and (max-width:767px) {
    div#to-owner-group-info {
        text-align: center;

        > a {
            text-decoration: none;

            > button {
                background-color: #ffffff;
                border: 1px solid transparent;
                border-radius: 30px;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
                height: 60px;
                position: relative;
                width: 270px;
        
                > div.mail-icon {
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    left: 20px;

                    > img {
                        width: 29px;
                        height: 20px;
                        object-fit: contain;
                    }
                }

                > div.text {
                    display: inline-block;
                    position: absolute;
                    top: 12px;
                    left: 64px;
                    text-align: left;
        
                    > p {
                        color: #222222;
                        font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.1rem;
                        margin-bottom: 0px;

                        > span {
                            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            color: #222222;
                        }
                    }
                }

                > div.greater-than {
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 25px;

                    > span {
                        border-radius: 20px;
                        color: #222222;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) {
    div#to-owner-group-info {
        text-align: center;

        > a {
            text-decoration: none;

            > button {
                background-color: #ffffff;
                border: 1px solid transparent;
                border-radius: 20px;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
                height: 44px;
                position: relative;
                width: 200px;
        
                > div.mail-icon {
                    display: inline-block;
                    position: absolute;
                    top: 14px;
                    left: 15px;

                    > img {
                        width: 23px;
                        height: 16px;
                        object-fit: contain;
                    }
                }

                > div.text {
                    display: inline-block;
                    position: absolute;
                    top: 5px;
                    left: 47px;
                    text-align: left;
        
                    > p {
                        color: #222222;
                        font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1rem;
                        margin-bottom: 0px;

                        > span {
                            font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.2rem;
                            color: #222222;
                        }
                    }
                }

                > div.greater-than {
                    display: inline-block;
                    position: absolute;
                    top: 13px;
                    right: 12px;

                    > span {
                        border-radius: 20px;
                        color: #222222;
                    }
                }
            }
        }
    }
}
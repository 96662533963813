@media screen and (max-width:767px) {
    div#sub-header {
        display: flex;
        margin: 0 20px;
        > div.contents {
            width: 100%;
            > div.top {
                display: flex;
                margin-bottom: 10px;

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.2rem;
                    letter-spacing: 0.92px;
                    margin-left: 10px;
                }
            }
            > div.middle {
                > div.contest-name {
                    color: #333333;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 1px;
                    line-height: 1.4;
                    margin-bottom: 5px;
                }
            }
            
            > div.vote-period {
                font-family: NotoSansCJKjp;
                font-size: 1.2rem;
                letter-spacing: 0.92px;
                color: #666666;
            }
            > div#contest-vote-limit {
                line-height: 1;
                height: 20px;
                margin-top: 25px;
                text-align: center;
            }
            > div#twitter-share {
                line-height: 1;
                margin-top: 15px;
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width:768px) {
    div#sub-header {
        display: flex;
        margin: 0 20px;
        > div.contents {
            width: 100%;
            > div.top {
                display: flex;
                margin-bottom: 15px;

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-left: 10px;
                }
                > div#contest-vote-limit {
                    line-height: 1;
                    height: 20px;
                    margin-left: auto;
                }
            }

            > div.middle {
                display: flex;
                > div.contest-name {
                    color: #333333;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.8rem;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                }
                > div#twitter-share {
                    margin-left: auto;
                }
            }
            
            > div.vote-period {
                font-family: NotoSansCJKjp;
                font-size: 1.3rem;
                letter-spacing: 1px;
                color: #666666;
            }
        }
    }
}
@media screen and (min-width:1025px) {
    div#sub-header {
        display: flex;
        margin: 0 20px;
        > div.contents {
            width: 100%;
            > div.top {
                display: flex;
                margin-bottom: 15px;

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-left: 10px;
                }
                > div#contest-vote-limit {
                    line-height: 1;
                    height: 20px;
                    margin-left: auto;
                }
            }
            > div.middle {
                display: flex;
                > div.contest-name {
                    color: #333333;
                    font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.8rem;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                }
                > div#twitter-share {
                    margin-left: auto;
                }
            }
            
            > div.vote-period {
                font-family: NotoSansCJKjp;
                font-size: 1.3rem;
                letter-spacing: 1px;
                color: #666666;
            }
        }
    }
}

div#privacy-policy {
    font-family: HiraKakuProN-W3, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;

    > div#breadcrumbs {
        background-color: #f0f0f0;
        margin: 0 calc(50% - 50vw);

        > div.breadcrumbs-item {
            margin-top: 9px;
            margin-bottom: 11px;
            color: #9a9a9a;
            font-size: 1.2rem;
            padding: 0;
        }
    }

    > div.privacy-policy {
        max-width: 620px;
        padding: 0;
        @media screen and (max-width:767px){
            margin: 20px 20px 0;
        }
        @media screen and (min-width:768px){
            margin: 40px auto 0;
        }

        > h1 {
            color: #333333;
            font-family: HiraKakuProN-W6, 'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            font-size: 1.6rem;
            letter-spacing: 1.23px;
            text-align: center;
            margin: 0;
        }
    
        > h2.title {
            color: #222222;
            font-family: HiraKakuProN-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            @media screen and (max-width:767px){
                font-size: 1.3rem;
            }
            @media screen and (min-width:768px){
                font-size: 1.4rem;
            }
            line-height: 1.64;
            margin-top: 30px;
            margin-bottom: 10px;
            > label.title-header {
                width: 2px;
                height: 21px;
                background-color: #ff0077;
            }
        }
    
        > p.body {
            font-size: 1.4rem;
            line-height: 1.64;
            color: #222222;
            margin-bottom: 0;
        }
        > #breadcrumbs {
            @media screen and (max-width:767px){
                margin-top: 30px;
            }
        }
    }
}
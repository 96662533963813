html {
    font-size: 62.5%;
    > body {
        > div#root {
            > div.content-area {
                @media screen and (max-width:1024px) and (min-width:768px) {
                    width: 100%;
                }
                @media screen and (min-width:1025px){
                    font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    padding: 0;
                    width: 1040px;
                    margin: 0 auto;
                }
            }
        }
        button:focus {
            outline:0;
        }
    }
}
@media screen and (max-width:767px) {
    div#contests-headline {
        background-size: cover;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin: 0 calc(50% - 50vw);
        overflow: hidden;
        position: relative;
        width: auto;
        min-height: 303px;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            background: inherit;
            filter: blur(15px);
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            margin: -15px;
            width: auto;
            z-index: -1;
        }

        > div.pickup-contest {
            background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #f7f7f7, #ededed);
            border-radius: 4px;
            margin: 10px auto 10px;
            max-width: 335px;
            min-height: 283px;
            width: auto;

            > div.left {
                padding: 5px 5px 0 5px;

                > img {
                    border-radius: 4px;
                    filter: blur(0px);
                    max-height: 115px;
                    height: 100%;
                    object-fit: contain;
                    max-width: 325px;
                    width: 100%;
                }
            }

            > div.right {
                margin: 0 5px 25px;
                width: auto;
                padding: 0 5px;

                > div#contest-status {
                    display: inline-block;
                    height: 19px;
                    margin-top: 10px;
                }

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.2rem;
                    letter-spacing: 1px;
                    margin-left: 10px;
                }

                > div.contest-name {
                    color: #333333;
                    display: -webkit-box;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.6rem;
                    line-height: 1.4;
                    letter-spacing: 0.89px;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    margin-top: 8px;
                    overflow: hidden;
                }

                > div.contest-period {
                    color: #666666;
                    font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.2rem;
                    line-height: 1;
                    letter-spacing: 0.92px;
                    margin-top: 10px;
                }

                > div.detail-btn {
                    text-align: center;
                    margin-top: 20px;

                    button.abled {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        border: 1px solid transparent;
                        border-radius: 20px;
                        height: 38px;
                        padding: 1px;
                        width: 180px;

                        > span {
                            background-color: #ffffff;
                            border-radius: 20px;
                            color: #ff3b48;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.4rem;
                            height: 34px;
                            letter-spacing: 1px;
                            text-align: center;
                        }

                        > span::after {
                            color: #ffffff;
                            position: absolute;
                            align-items: center;
                            border-radius: 30px;
                            display: flex;
                            font-size: 1.4rem;
                            height: 40px;
                            letter-spacing: 1px;
                            justify-content: center;
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            width: 180px;
                            content: "詳細はこちら";
                            opacity: 0;
                            transition: .3s;
                        }

                        span:hover::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) and (min-width:768px) {
    div#contests-headline {
        background-size: cover;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin: 0 calc(50% - 50vw);
        overflow: hidden;
        position: relative;
        width: auto;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            background: inherit;
            filter: blur(15px);
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            margin: -15px;
            width: auto;
            z-index: -1;
        }

        > div.pickup-contest {
            background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #f7f7f7, #ededed);
            border-radius: 8px;
            display: flex;
            height: 240px;
            margin: 20px 20px 20px;

            > div.left {
                height: 230px;
                margin: 5px 0 5px 5px;
                max-width: 646px;
                width: 100%;

                > img {
                    border-radius: 8px;
                    filter: blur(0px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }

            > div.right {
                margin: 0 20px;
                width: 394px;
                position: relative;

                > div#contest-status {
                    display: inline-block;
                    height: 19px;
                    margin-top: 25px;
                }

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-left: 5px;
                }

                > div.contest-name {
                    color: #333333;
                    display: -webkit-box;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 2.4rem;
                    line-height: 1.4;
                    letter-spacing: 1.33px;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    margin-top: 10px;
                    overflow: hidden;
                }

                > div.contest-period {
                    color: #666666;
                    font-family: NotoSansCJKjp;
                    font-size: 1.2rem;
                    letter-spacing: 1px;
                    margin-top: 10px;
                }

                > div.detail-btn {
                    text-align: center;
                    margin-top: 20px;

                    button.abled {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        border: 1px solid transparent;
                        border-radius: 30px;
                        padding: 1px;
                        position: absolute;
                        text-align: center;
                        right: 8%;
                        bottom: 5%;
                        width: 206px;

                        > span {
                            background-color: #ffffff;
                            border-radius: 30px;
                            color: #ff3b48;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.6rem;
                            height: 40px;
                            letter-spacing: 1px;
                            text-align: center;
                        }
                        > span::after {
                            color: #ffffff;
                            position: absolute;
                            align-items: center;
                            border-radius: 30px;
                            display: flex;
                            font-size: 1.6rem;
                            height: 100%;
                            letter-spacing: 1px;
                            justify-content: center;
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            width: 100%;
                            content: "詳細はこちら";
                            opacity: 0;
                            transition: .3s;
                        }
                        span:hover::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1025px) {
    div#contests-headline {
        background-size: cover;
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin: 0 calc(50% - 50vw);
        overflow: hidden;
        position: relative;
        width: auto;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            background: inherit;
            filter: blur(15px);
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            margin: -15px;
            width: auto;
            z-index: -1;
        }

        > div.pickup-contest {
            background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #f7f7f7, #ededed);
            border-radius: 8px;
            display: flex;
            height: 240px;
            max-width: 1040px;
            margin: 20px auto 20px;
            width: 100%;

            > div.left {
                height: 230px;
                margin: 5px 0 5px 5px;
                width: 646px;

                > img {
                    border-radius: 8px;
                    filter: blur(0px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }

            > div.right {
                margin: 0 20px;
                width: 394px;
                padding: 0;
                position: relative;

                > div#contest-status {
                    display: inline-block;
                    height: 19px;
                    margin-top: 25px;
                }

                > div.college-name {
                    color: #333333;
                    display: inline-block;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-left: 5px;
                }

                > div.contest-name {
                    color: #333333;
                    display: -webkit-box;
                    font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                    font-size: 2.4rem;
                    line-height: 1.4;
                    letter-spacing: 1.33px;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    margin-top: 10px;
                    overflow: hidden;
                }

                > div.contest-period {
                    color: #666666;
                    font-family: NotoSansCJKjp;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    margin-top: 10px;
                }

                > div.detail-btn {
                    text-align: center;
                    margin-top: 20px;

                    button.abled {
                        background: linear-gradient(to right,#ff7717,#ff0078);
                        border: 1px solid transparent;
                        border-radius: 30px;
                        padding: 1px;
                        position: absolute;
                        text-align: center;
                        right: 20%;
                        bottom: 10%;
                        width: 206px;

                        > span {
                            background-color: #ffffff;
                            border-radius: 30px;
                            color: #ff3b48;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.6rem;
                            height: 40px;
                            letter-spacing: 1px;
                            text-align: center;
                        }
                        > span::after {
                            color: #ffffff;
                            position: absolute;
                            align-items: center;
                            border-radius: 30px;
                            display: flex;
                            font-size: 1.6rem;
                            height: 100%;
                            letter-spacing: 1px;
                            justify-content: center;
                            background: linear-gradient(to right,#ff7717,#ff0078);
                            width: 100%;
                            content: "詳細はこちら";
                            opacity: 0;
                            transition: .3s;
                        }
                        span:hover::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
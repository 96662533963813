@media screen and (max-width:767px) {
    div#candidate-profile-info {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin-bottom: 30px;
        > div.candidate-profile-contents {
            > div.candidate-images {
                > #slider {
                    padding: 0;
                    > #carousel {
                        > div.carousel-inner {
                            height: 375px;
                            width: 100%;
                            > div.carousel-item{
                                > img {
                                    height: 375px;
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                        > ul.list-inline {
                            white-space:nowrap;
                            overflow-x:auto;
                            -ms-overflow-style: none;
                            scrollbar-width: none;
                            
                        }
                        > ul.list-inline::-webkit-scrollbar {
                            display:none;
                        }
                        
                        > ul.carousel-indicators {
                            position: static;
                            display: block;
                            width: 100%;
                            height: 100%;
                            margin: 0 auto;
                            > li.list-inline-item {
                                text-indent: initial;
                                height: 100%;
                                margin-left: 5px;
                                width: 60px;
                                img {
                                    border-radius: 50%;
                                    height: 60px;
                                    object-fit: contain;
                                    width: 60px;
                                }
                                &.active img {
                                    opacity: 1;
                                }
                                &.list-inline-item:first-child {
                                    margin-left: 20px;
                                }
                                &.list-inline-item:last-child {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }
            > div.candidate-profile {
                margin: 0 20px;
                width: auto;
                > div.top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    > div.entry-number {
                        align-items: center;
                        border-radius: 4px;
                        background-image: linear-gradient(258deg, #ffbf5d 100%, #ffd241 3%);
                        display: flex;
                        min-height: 22px;
                        margin-top: 10px;
                        height: auto;
                        justify-content: center;
                        margin-bottom: 10px;
                        text-align: center;
                        width: 96px;
    
                        > span {
                            color: #ffffff;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.1rem;
                            letter-spacing: 0.86px;
                            word-break: break-word;
                        }
                    }
                }
            
                > div.pair {
                    display: flex;
                    justify-content: space-between;
                    > div.candidate-name {
                        min-width: 150px;
                        width: auto;
                        > p.name {
                            color: #333333;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 2.4rem;
                            letter-spacing: 2px;
                            margin-bottom: 5px;
                
                            > span.candidate-ranking {
                                img {
                                    height: 27px;
                                    object-fit: contain;
                                    margin-right: 10px;
                                    width: 32px;
                                }
                            }
                        }
                        > p.name-romaji {
                            color: #ffb4b4;
                            font-family: Futura,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.2rem;
                            font-weight: 500;
                            height: 19px;
                            letter-spacing: 2px;
                            margin-bottom: 15px;
                        }
                    }

                    > div.btns-sns {
                        text-align: right;
                        min-width: 130px;
                        width: auto;
                        > div.btn-sns {
                            display: inline-block;
                            position: relative;
                            img {
                                width: 32px;
                                height: 32px;
                            }
                            margin-right: 10px;
                    
                            > a {
                                display: inline-block;
                            }
                            > a:hover {
                                img {
                                    transition: .3s;
                                    visibility: hidden;
                                }
                            }
                            > a::after {
                                position: absolute;
                                top: 0;
                                left: 0;
                                align-items: center;
                                display: flex;
                                font-size: 1.6rem;
                                height: 100%;
                                letter-spacing: 1px;
                                justify-content: center;
                                width: 100%;
                                content: "";
                                opacity: 0;
                                transition: .3s;
                            }
                            > a:hover::after {
                                opacity: 1;
                            }
                    
                            > a.btn-mixch-image::after {
                                background: url("/sns-btn/btn-mixch-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-tw-image::after {
                                background: url("/sns-btn/btn-tw-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-insta-image::after {
                                background: url("/sns-btn/btn-insta-hover.png") no-repeat;
                                background-size: contain;
                    
                                > img {
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                }
                > div.contest-info {
                    > p.contest-name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.6rem;
                        margin-bottom: 5px;
                        letter-spacing: 1px;
                        line-height: 1.4;
                    }
                
                    > p.vote-period {
                        color: #666666;
                        font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.2rem;
                        letter-spacing: 1px;
                        margin-bottom: 20px;
                    }
                }

                > div.profile-details {
                    margin-bottom: 15px;
                    display: flex;
        
                    > span.name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.4rem;
                        letter-spacing: 0.88px;
                        width: 80px;
                        margin-right: 20px;
                    }
        
                    > span.val  {
                        color: #333333;
                        font-size: 1.4rem;
                        letter-spacing: 1px;
                        width: 100%;
                    }
                }
            }
            > div.profile-details:last-child {
                margin-bottom: 0;
            }
        }
        > div.candidate-profile-btn {
            margin: 50px auto 0;
            position: fixed;
            z-index: 1;
            bottom: 6%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
    
            > div#contest-vote-limit {
                letter-spacing: 1.08px;
                text-align: center;
                margin-bottom: 10px;
            }
            > div.vote-btn {
                margin: 0 auto;
                width: 100%;
                height: auto;
                text-align: center;
                > button.abled {
                    box-shadow: 0 3px 5px 0 #ff9299;
                    height: 40px;
                    width: 200px;
                }
            }
        }
    }
}
@media screen and (max-width:1023px) and (min-width:768px) {
    div#candidate-profile-info {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin-bottom: 50px;
        > div.share-btn {
            height: 50px;
            display: flex;
            align-items: center;
            > div#twitter-share {
                margin-left: auto;
                margin-right: 30px;
            }
        }
        > div.candidate-profile-contents {
            display: flex;
            justify-content: center;
            margin: auto 20px;
            > div.candidate-images {
                height: 400px;
                width: 400px;
                > div.carousel {
                    text-align: center;
                    div.carousel-inner {
                        height: 400px;
                        width: 100%;
                        > div.carousel-item{
                            > img {
                                height: 400px;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    > a.carousel-control-prev,
                    > a.carousel-control-next {
                        width: auto;
                        > .prev-icon,
                        > .next-icon {
                            height: 40px;
                            object-fit: contain;
                            width: 40px;
                        }
                    }
                    > .carousel-indicators {
                        position: absolute;
                        bottom: -45px;
                        li {
                            background-color: #909090;
                            border-radius: 50%;
                            margin: 1px 5px;
                            height: 6px;
                            max-width: 6px;
                        }
                    }
                }
            }
            > div.candidate-profile {
                margin-left: 30px;
                width: 338px;
                > div.entry-number {
                    align-items: center;
                    border-radius: 4px;
                    background-image: linear-gradient(258deg, #ffbf5d 100%, #ffd241 3%);
                    display: flex;
                    min-height: 22px;
                    height: auto;
                    justify-content: center;
                    margin-bottom: 10px;
                    text-align: center;
                    width: 105px;

                    > span {
                        color: #ffffff;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.2rem;
                        letter-spacing: 0.86px;
                        word-break: break-word;
                    }
                }
            
                > div.pair {
                    display: flex;
                    justify-content: space-between;
                    > div.candidate-name {
                        > p.name {
                            color: #333333;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 3rem;
                            letter-spacing: 2px;
                            margin-bottom: 5px;
                            width: 200px;
                
                            > span.candidate-ranking {
                                img {
                                    height: 27px;
                                    object-fit: contain;
                                    margin-right: 10px;
                                    width: 32px;
                                }
                            }
                        }
                        > p.name-romaji {
                            color: #ffb4b4;
                            font-family: Futura,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            font-weight: 500;
                            height: 19px;
                            letter-spacing: 2px;
                            margin-bottom: 15px;
                        }
                    }

                    > div.btns-sns {
                        text-align: right;
                        > div.btn-sns {
                            display: inline-block;
                            position: relative;
                            img {
                                width: 32px;
                                height: 32px;
                            }
                            margin-right: 10px;
                    
                            > a {
                                display: inline-block;
                            }
                            > a:hover {
                                img {
                                    transition: .3s;
                                    visibility: hidden;
                                }
                            }
                            > a::after {
                                position: absolute;
                                top: 0;
                                left: 0;
                                align-items: center;
                                display: flex;
                                font-size: 1.6rem;
                                height: 100%;
                                letter-spacing: 1px;
                                justify-content: center;
                                width: 100%;
                                content: "";
                                opacity: 0;
                                transition: .3s;
                            }
                            > a:hover::after {
                                opacity: 1;
                            }
                    
                            > a.btn-mixch-image::after {
                                background: url("/sns-btn/btn-mixch-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-tw-image::after {
                                background: url("/sns-btn/btn-tw-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-insta-image::after {
                                background: url("/sns-btn/btn-insta-hover.png") no-repeat;
                                background-size: contain;
                    
                                > img {
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                }
                > div.contest-info {
                    > p.contest-name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.8rem;
                        letter-spacing: 1px;
                    }
                
                    > p.vote-period {
                        color: #666666;
                        font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.3rem;
                        letter-spacing: 1px;
                        margin-bottom: 30px;
                    }
                }

                > div.profile-details {
                    margin-bottom: 15px;
                    display: flex;
        
                    > span.name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.4rem;
                        letter-spacing: 0.88px;
                        width: 80px;
                        margin-right: 20px;
                    }
        
                    > span.val  {
                        color: #333333;
                        font-size: 1.6rem;
                        letter-spacing: 2px;
                        line-height: 1.4;
                        width: 368px;
                    }
                }
                > div.profile-details:last-child {
                    margin-bottom: 0;
                }
            }
        }
        > div.candidate-profile-btn {
            margin: 50px auto 0;
    
            > div#contest-vote-limit {
                letter-spacing: 1.08px;
                text-align: center;
                margin-bottom: 15px;
            }
    
            > div.vote-btn {
                margin: 0 auto;
                width: 100%;
                height: auto;
                text-align: center;
                > button {
                    width: 260px;
                    height: 50px;
                }
            }
        }
    }
}
@media screen and (min-width:1024px) {
    div#candidate-profile-info {
        font-family: HiraKakuProN-W3,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        margin-bottom: 50px;
        > div.share-btn {
            height: 50px;
            display: flex;
            align-items: center;
            > div#twitter-share {
                margin-left: auto;
                margin-right: 30px;
            }
        }
        > div.candidate-profile-contents {
            display: flex;
            margin: 0 20px;
            > div.candidate-images {
                height: 500px;
                width: 500px;
                > div.carousel {
                    text-align: center;
                    position: relative;
                    div.carousel-inner {
                        height: 500px;
                        width: 100%;
                        > div.carousel-item{
                            > img {
                                height: 500px;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    > a.carousel-control-prev,
                    > a.carousel-control-next {
                        width: auto;
                        > .prev-icon,
                        > .next-icon {
                            height: 40px;
                            object-fit: contain;
                            width: 40px;
                        }
                    }
                    > .carousel-indicators {
                        position: absolute;
                        bottom: -45px;
                        li {
                            background-color: #909090;
                            border-radius: 50%;
                            margin: 1px 5px;
                            height: 6px;
                            max-width: 6px;
                        }
                    }
                }
            }
            > div.candidate-profile {
                margin: auto auto auto 50px;
                > div.entry-number {
                    align-items: center;
                    border-radius: 4px;
                    background-image: linear-gradient(258deg, #ffbf5d 100%, #ffd241 3%);
                    display: flex;
                    min-height: 22px;
                    height: auto;
                    justify-content: center;
                    margin-bottom: 10px;
                    text-align: center;
                    width: 105px;

                    > span {
                        color: #ffffff;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.2rem;
                        letter-spacing: 0.86px;
                        word-break: break-word;
                    }
                }
            
                > div.pair {
                    display: flex;
                    justify-content: space-between;
                    > div.candidate-name {
                        > p.name {
                            color: #333333;
                            font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 3rem;
                            letter-spacing: 2px;
                            margin-bottom: 5px;
                            width: 305px;
                
                            > span.candidate-ranking {
                                img {
                                    height: 27px;
                                    object-fit: contain;
                                    margin-right: 10px;
                                    width: 32px;
                                }
                            }
                        }
                        > p.name-romaji {
                            color: #ffb4b4;
                            font-family: Futura,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                            font-size: 1.4rem;
                            font-weight: 500;
                            height: 19px;
                            letter-spacing: 2px;
                            margin-bottom: 15px;
                        }
                    }

                    > div.btns-sns {
                        text-align: right;
                        > div.btn-sns {
                            display: inline-block;
                            position: relative;
                            img {
                                width: 40px;
                                height: 40px;
                            }
                            margin-right: 15px;
                    
                            > a {
                                display: inline-block;
                            }
                            > a:hover {
                                img {
                                    transition: .3s;
                                    visibility: hidden;
                                }
                            }
                            > a::after {
                                position: absolute;
                                top: 0;
                                left: 0;
                                align-items: center;
                                display: flex;
                                font-size: 1.6rem;
                                height: 100%;
                                letter-spacing: 1px;
                                justify-content: center;
                                width: 100%;
                                content: "";
                                opacity: 0;
                                transition: .3s;
                            }
                            > a:hover::after {
                                opacity: 1;
                            }
                    
                            > a.btn-mixch-image::after {
                                background: url("/sns-btn/btn-mixch-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-tw-image::after {
                                background: url("/sns-btn/btn-tw-hover.png") no-repeat;
                                background-size: contain;
                            }
                    
                            > a.btn-insta-image::after {
                                background: url("/sns-btn/btn-insta-hover.png") no-repeat;
                                background-size: contain;
                    
                                > img {
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                }
                > div.contest-info {
                    > p.contest-name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.8rem;
                        margin-bottom: 10px;
                        letter-spacing: 1px;
                    }
                
                    > p.vote-period {
                        color: #666666;
                        font-family: NotoSansCJKjp,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.3rem;
                        letter-spacing: 1px;
                        margin-bottom: 30px;
                    }
                }

                > div.profile-details {
                    margin-bottom: 15px;
                    display: flex;
        
                    > span.name {
                        color: #333333;
                        font-family: HiraginoSans-W6,'Helvetica Neue',Helvetica,Arial,Verdana,"游ゴシック","游ゴシック体",YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
                        font-size: 1.4rem;
                        letter-spacing: 0.88px;
                        width: 80px;
                        margin-right: 20px;
                    }
        
                    > span.val  {
                        color: #333333;
                        font-size: 1.6rem;
                        letter-spacing: 2px;
                        line-height: 1.4;
                        width: 370px;
                    }
                }
                > div.profile-details:last-child {
                    margin-bottom: 0;
                }
            }
        }
        > div.candidate-profile-btn {
            margin: 50px auto 0;
    
            > div#contest-vote-limit {
                letter-spacing: 1.08px;
                text-align: center;
                margin-bottom: 15px;
            }
    
            > div.vote-btn {
                margin: 0 auto;
                width: 100%;
                height: auto;
                text-align: center;
                > button {
                    width: 260px;
                    height: 50px;
                }
            }
        }
    }
}
.candidate-profile-image {
    > .modal-content {
        height: auto;
        width: 100%;
        border-radius: 6px;
        > .modal-header {
            border-radius: 6px;
            @media screen and (max-width:767px) {
                padding: 20px;
            }
            @media screen and (min-width:768px) {
                padding: 40px;
            }
            > img.close-btn {
                position: absolute;
                @media screen and (max-width:767px) {
                    top: 5px;
                    right: 5px;
                }
                @media screen and (min-width:768px) {
                    top: 20px;
                    right: 20px;
                }
                width: 14px;
                height: 14px;;
                margin-left: auto;
                cursor: pointer;
            }
            > img.candidate-images {
                object-fit: contain;
                width: 100%;
                height: auto;
            }
        }
    }
}